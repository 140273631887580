<template>
  <div>
    <div
      v-if="Object.keys(campaign).length === 0"
      class="d-flex justify-content-center mb-1"
    >
      <b-spinner label="Loading..." />
    </div>
    <div v-else>
      <breadcrumbs
        :breadcrumb-items="breadcrumbItems"
      />
      <UserToolbar
        v-if="account && Object.keys(account).length !== 0"
        :account="account"
      />
      <b-card>
        <CampaignToolbar
          v-if="Object.keys(campaign).length !== 0"
          :campaign="campaign"
          closing
        />
      </b-card>
      <b-form @submit.prevent>
        <validation-observer ref="validationCampaignRules">
          <b-tabs
            pills
          >
            <b-tab active>
              <!-- title -->
              <template #title>
                <feather-icon
                  icon="SettingsIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">{{ $t('Settings') }}</span>
              </template>
              <b-card>
                <div class="head-card">
                  <div class="head-card-top">
                    <h4>{{ $t('Main settings') }}</h4>
                    <feather-icon
                      v-b-tooltip.hover.top="$t('Close')"
                      icon="XIcon"
                      size="18"
                      class="toggle-close cursor-pointer"
                      @click="$router.go(-1)"
                    />
                  </div>
                  <hr>
                </div>
                <b-row>
                  <b-col md="12">
                    <b-form-group
                      :label="$t('campaigns.campaigntitle')"
                      label-for="i-title"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="title"
                        rules="required|unique"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length == 0 || 'is-invalid'"
                        >
                          <b-input-group-prepend is-text>
                            <feather-icon icon="LayoutIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="i-title"
                            v-model="campaign.title"
                            :placeholder="$t('campaigns.campaigntitle')"
                            :state="errors.length > 0 ? false:null"
                            autocomplete="title"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group
                      :label="$t('campaigns.status')"
                      label-for="i-status"
                    >
                      <b-form-select
                        v-model="campaign.status"
                        :options="statusesLocale"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <app-collapse
                      accordion
                      type="border"
                    >
                      <app-collapse-item
                        :title="$t('campaigns.more_settings')"
                        :is-visible="true"
                      >
                        <b-form-group
                          :label="$t('banners.targetings.startdate')"
                          label-for="i-startdate"
                        >
                          <b-form-datepicker
                            id="i-startdate"
                            v-model="startdate"
                            today-button
                            reset-button
                            close-button
                            :placeholder="$t('banners.targetings-selects.date')"
                            :hide-header="true"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                          />
                        </b-form-group>

                        <b-form-group
                          :label="$t('banners.targetings.enddate')"
                          label-for="i-enddate"
                        >
                          <b-form-datepicker
                            id="i-enddate"
                            v-model="enddate"
                            today-button
                            reset-button
                            close-button
                            :placeholder="$t('banners.targetings-selects.date')"
                            :hide-header="true"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                          />
                        </b-form-group>

                        <b-form-group
                          :label="$t('campaigns.dailyclicks')"
                          label-for="i-dailyclicks"
                        >
                          <b-input-group
                            class="input-group-merge"
                            :class="{disabled: use_auto}"
                          >
                            <b-input-group-prepend is-text>
                              <feather-icon icon="MousePointerIcon" />
                            </b-input-group-prepend>
                            <b-form-input
                              id="i-dailyclicks"
                              v-model="campaign.dailyclicks"
                              type="number"
                              placeholder="0"
                              :disabled="use_auto"
                            />
                          </b-input-group>
                        </b-form-group>

                        <b-form-group>
                          <b-form-checkbox
                            v-model="use_auto"
                            :selected="use_auto"
                          >
                            {{ $t('campaigns.useauto') }}
                          </b-form-checkbox>
                        </b-form-group>

                        <b-alert
                          variant="primary"
                          show
                        >
                          <div class="alert-body">
                            <span>{{ $t('campaigns.filterswarning') }}</span>
                          </div>
                        </b-alert>
                      </app-collapse-item>
                    </app-collapse>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    md="12"
                    class="d-flex align-items-center justify-content-end mt-1"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-secondary"
                      class="mr-0 mr-sm-1"
                      @click="$router.go(-1)"
                    >
                      <feather-icon
                        icon="ChevronLeftIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">{{ $t('form.back') }}</span>
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="campaignUpdate"
                    >
                      <span class="align-middle">{{ $t('form.save') }}</span>
                      <feather-icon
                        icon="ChevronRightIcon"
                        class="ml-50"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-tab>
            <!--/ general tab -->
            <b-tab v-if="authUserData.role.includes('admin')">
              <!-- title -->
              <template #title>
                <feather-icon
                  icon="BoxIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Advertorial</span>
              </template>
              <b-card>
                <div class="head-card">
                  <div class="head-card-top">
                    <h4>Advertorial</h4>
                    <feather-icon
                      v-b-tooltip.hover.top="$t('Close')"
                      icon="XIcon"
                      size="18"
                      class="toggle-close cursor-pointer"
                      @click="$router.go(-1)"
                    />
                  </div>
                  <hr>
                </div>
                <b-row>
                  <b-col md="12">
                    <b-form-group>
                      <b-form-checkbox
                        v-model="campaign.enable_advertorial"
                        :selected="campaign.enable_advertorial"
                      >
                        {{ $t('banners.targetings-selects.active') }}
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="campaign.enable_advertorial"
                    md="12"
                  >
                    <form @submit.prevent="store">
                      <b-form-group
                        label="Template"
                        label-for="i-template"
                      >
                        <ckeditor
                          id="i-template"
                          v-model="campaign.advertorial_html"
                          :editor="editor"
                          :config="{...editorConfig, }"
                        />
                      </b-form-group>
                    </form>
                    <b-form-group
                      :label="$t('sites.advertorial_url')"
                      label-for="i-advertorial_url"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="advertorial_url"
                        rules="required"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length == 0 || 'is-invalid'"
                        >
                          <b-input-group-prepend is-text>
                            <feather-icon icon="LinkIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="i-advertorial_url"
                            v-model="campaign.advertorial_url"
                            placeholder="https://domain.com/advertorial"
                            autocomplete="advertorial"
                            :state="errors.length > 0 ? false:null"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Copy code!"
                      label-for="i-template"
                    >
                      <b-form-textarea
                        ref="tarea"
                        :value="copyCode()"
                        variant="primary"
                        placeholder="Code"
                        rows="4"
                      />
                    </b-form-group>
                    <b-button
                      v-clipboard:copy="copyCode()"
                      v-clipboard:success="onCopy"
                      v-clipboard:error="onError"
                      v-b-tooltip.hover.top="$t('widgets.copybutton')"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="success"
                    >
                      <feather-icon icon="CopyIcon" /> {{ $t('form.copy') }}
                    </b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    md="12"
                    class="d-flex align-items-center justify-content-end mt-1"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-secondary"
                      class="mr-0 mr-sm-1"
                      @click="$router.go(-1)"
                    >
                      <feather-icon
                        icon="ChevronLeftIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">{{ $t('form.back') }}</span>
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="campaignUpdate"
                    >
                      <span class="align-middle">{{ $t('form.save') }}</span>
                      <feather-icon
                        icon="ChevronRightIcon"
                        class="ml-50"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-tab>
            <!--/ advertorial tab -->
            <b-tab v-if="authUserData.role.includes('admin')">
              <!-- title -->
              <template #title>
                <feather-icon
                  icon="ShieldIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">{{ $t('campaigns.adminsettings') }}</span>
              </template>
              <b-card>
                <div class="head-card">
                  <div class="head-card-top">
                    <h4>{{ $t('campaigns.adminsettings') }}</h4>
                    <feather-icon
                      v-b-tooltip.hover.top="$t('Close')"
                      icon="XIcon"
                      size="18"
                      class="toggle-close cursor-pointer"
                      @click="$router.go(-1)"
                    />
                  </div>
                  <hr>
                </div>
                <b-row>
                  <b-col md="6">
                    <b-form-group>
                      <b-form-checkbox
                        v-model="is_moderate"
                        :selected="is_moderate"
                      >
                        {{ $t('campaigns.moderated') }}
                      </b-form-checkbox>
                    </b-form-group>

                    <b-form-group
                      :label="$t('campaigns.clickprice')"
                      label-for="i-cost"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="cost"
                        rules="required"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length == 0 || 'is-invalid'"
                        >
                          <b-input-group-prepend is-text>
                            <span>{{ campaign.currency }}</span>
                          </b-input-group-prepend>
                          <b-form-input
                            id="i-cost"
                            v-model="campaign.cost"
                            type="number"
                            placeholder="1.5"
                            :state="errors.length > 0 ? false:null"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group
                      :label="$t('campaigns.weight')"
                      label-for="i-weight"
                    >
                      <b-input-group
                        class="input-group-merge"
                      >
                        <b-input-group-prepend is-text>
                          <feather-icon icon="DiscIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="i-weight"
                          v-model="campaign.weight"
                          type="number"
                          placeholder="1.0"
                        />
                      </b-input-group>
                    </b-form-group>

                    <b-form-group
                      :label="$t('campaigns.utm')"
                      label-for="i-utm"
                    >
                      <b-input-group
                        class="input-group-merge"
                      >
                        <b-input-group-prepend is-text>
                          <feather-icon icon="TagIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="i-utm"
                          v-model="campaign.utm"
                          placeholder="UTM marks"
                        />
                      </b-input-group>
                    </b-form-group>

                    <b-form-group
                      :label="$t('campaigns.track_urls')"
                      label-for="i-track_urls"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="LinkIcon" />
                        </b-input-group-prepend>
                        <b-form-textarea
                          id="i-track_urls"
                          v-model="campaign.track_urls"
                          :placeholder="$t('campaigns.track_urls')"
                        />
                      </b-input-group>
                    </b-form-group>

                    <b-form-group
                      :label="$t('campaigns.adomain')"
                      label-for="i-adomain"
                    >
                      <b-input-group
                        class="input-group-merge"
                      >
                        <b-input-group-prepend is-text>
                          <feather-icon icon="LayoutIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="i-adomain"
                          v-model="campaign.adomain"
                          :placeholder="$t('campaigns.adomain')"
                        />
                      </b-input-group>
                    </b-form-group>

                    <b-form-group
                      :label="$t('page.features.impressionRate')"
                      label-for="i-impression_size_alert"
                    >
                      <b-input-group
                        class="input-group-merge"
                      >
                        <b-input-group-prepend is-text>
                          <feather-icon icon="BarChart2Icon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="i-impression_size_alert"
                          v-model="impressionSizeAlert"
                          type="number"
                          placeholder="200"
                        />
                      </b-input-group>
                    </b-form-group>

                    <b-form-group>
                      <b-form-checkbox
                        v-model="enable_openrtb"
                        :selected="enable_openrtb"
                      >
                        {{ $t('campaigns.enable_openrtb') }}
                      </b-form-checkbox>
                    </b-form-group>

                    <b-form-group
                      :label="$t('campaigns.ecpm_bid')"
                      label-for="i-ecpm_bid"
                    >
                      <b-input-group
                        class="input-group-merge"
                      >
                        <b-input-group-prepend is-text>
                          <feather-icon icon="BarChart2Icon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="i-ecpm_bid"
                          v-model="ecpm_bid"
                          type="number"
                          placeholder="0.1"
                        />
                      </b-input-group>
                    </b-form-group>
                    <b-form-group>
                      <b-form-checkbox
                        v-model="only_openrtb"
                        :selected="only_openrtb"
                      >
                        {{ $t('campaigns.only_openrtb') }}
                      </b-form-checkbox>
                    </b-form-group>
                    <b-form-group>
                      <b-form-checkbox
                        v-model="hide_clicks_for_sites"
                        :selected="hide_clicks_for_sites"
                      >
                        {{ $t('campaigns.hide_clicks_sites') }}
                      </b-form-checkbox>
                    </b-form-group>

                    <b-form-group>
                      <b-form-checkbox
                        v-model="on_image_load"
                        :selected="on_image_load"
                      >
                        {{ $t('campaigns.on_image_load') }}
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      :label="$t('sites.country')"
                      label-for="i-country"
                    >
                      <v-select
                        id="i-country"
                        v-model="country"
                        :placeholder="$t('sites.choosecountry')"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="target_countries"
                        label="text"
                      />
                    </b-form-group>
                    <b-form-group
                      :label="$t('campaigns.clickfilter')"
                      label-for="i-clickfilter"
                    >
                      <b-form-select
                        id="i-clickfilter"
                        v-model="campaign.clickfilter"
                        :options="clickfilterLocale"
                      />
                    </b-form-group>
                    <b-form-group
                      :label="$t('campaigns.type')"
                      label-for="i-type"
                    >
                      <b-form-select
                        id="i-type"
                        v-model="selectedType"
                        :options="campaignTypeLocale"
                      />
                    </b-form-group>
                    <div v-if="selectedType === 'adsense'">
                      <b-form-group
                        :label="$t('campaigns.src_code')"
                        label-for="i-src_code"
                      >
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="CodeIcon" />
                          </b-input-group-prepend>
                          <b-form-textarea
                            id="i-src_code"
                            v-model="campaign.src_code"
                            :placeholder="$t('campaigns.src_code')"
                          />
                        </b-input-group>
                      </b-form-group>
                      <b-form-group
                        :label="$t('campaigns.widgets')"
                        label-for="i-widgets"
                      >
                        <v-select
                          id="i-widgets"
                          v-model="widgetsCampaign"
                          :placeholder="$t('campaigns.selectid')"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="widgets"
                          label="text"
                          multiple
                        />
                      </b-form-group>

                      <b-form-group
                        :label="$t('banners.targetings.country')"
                        label-for="i-countries"
                      >
                        <b-form-radio-group
                          v-model="countryCampaign.status"
                          class="mb-1"
                          button-variant="outline-primary"
                          :options="optionsRadioLocale"
                          buttons
                          size="sm"
                          name="radios-btn-default"
                        />
                        <v-select
                          id="i-countries"
                          v-model="countriesCampaign"
                          :class="statusVariant(countryCampaign.status)"
                          :placeholder="$t('banners.targetings-selects.country')"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="partner_countries"
                          label="text"
                          multiple
                        />
                      </b-form-group>
                    </div>
                    <div v-if="selectedType === 'cpcfeed'">
                      <b-form-group
                        label="Api url"
                        label-for="i-api_url"
                      >
                        <b-input-group
                          class="input-group-merge"
                        >
                          <b-input-group-prepend is-text>
                            <feather-icon icon="Link2Icon" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="i-api_url"
                            v-model="api_url"
                            placeholder="Api url"
                          />
                        </b-input-group>
                      </b-form-group>

                      <b-form-group
                        :label="$t('campaigns.cpcfeedapiprocessor')"
                        label-for="i-cpc_feed_api_processor"
                      >
                        <b-form-select
                          id="i-cpc_feed_api_processor"
                          v-model="cpc_feed_api_processor"
                          :options="cpcFeedApiProcessor"
                        />
                      </b-form-group>

                      <b-form-group
                        :label="$t('campaigns.widgets')"
                        label-for="i-widgets"
                      >
                        <v-select
                          id="i-widgets"
                          v-model="widgetsCampaign"
                          :placeholder="$t('campaigns.selectid')"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="widgets"
                          label="text"
                          multiple
                        />
                      </b-form-group>
                    </div>
                    <b-form-group
                      :label="$t('sites.category')"
                      label-for="i-category"
                    >
                      <b-form-select
                        id="i-category"
                        v-model="main_category"
                        :options="campaignCategory"
                        @change="changeCategory"
                      />
                    </b-form-group>

                    <b-form-group
                      :label="$t('campaigns.sub_category')"
                      label-for="i-subcategory"
                    >
                      <b-form-select
                        id="i-subcategory"
                        v-model="sub_category"
                        :options="campaignSubCategory[main_category]"
                      />
                    </b-form-group>

                    <b-form-group
                      :label="$t('banners.targetings.site_language')"
                      label-for="i-site_language"
                    >
                      <b-form-radio-group
                        v-model="campaign.site_language.status"
                        class="mb-1"
                        button-variant="outline-primary"
                        :options="optionsRadioLocale"
                        buttons
                        size="sm"
                        name="radios-btn-default"
                      />
                      <v-select
                        id="i-site_language"
                        v-model="siteLanguages"
                        :class="statusVariant(campaign.site_language.status)"
                        :placeholder="$t('banners.targetings-selects.language')"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="languages"
                        label="text"
                        multiple
                      />
                    </b-form-group>

                    <b-form-group
                      :label="$t('banners.targetings.remarketing')"
                      label-for="i-retargeting"
                    >
                      <b-form-radio-group
                        v-model="campaign.remarketing.status"
                        class="mb-1"
                        button-variant="outline-primary"
                        :options="optionsRadioLocale"
                        buttons
                        size="sm"
                        name="radios-btn-default"
                      />
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="HashIcon" />
                        </b-input-group-prepend>
                        <b-form-textarea
                          id="i-retargeting"
                          v-model="remarketing.params.campaigns"
                          placeholder="Ids"
                        />
                      </b-input-group>
                    </b-form-group>

                    <b-form-group
                      :label="$t('banners.targetings.capping')"
                      label-for="i-capping"
                    >
                      <b-form-radio-group
                        v-model="campaign.capping.status"
                        class="mb-1"
                        button-variant="outline-primary"
                        :options="optionsRadioLocale"
                        buttons
                        size="sm"
                        name="radios-btn-default"
                      />
                      <b-input-group
                        class="input-group-merge"
                      >
                        <b-input-group-prepend is-text>
                          <feather-icon icon="ClockIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="i-capping"
                          v-model="cappingCampaign"
                          :placeholder="$t('banners.targetings.hours')"
                          type="number"
                        />
                      </b-input-group>
                    </b-form-group>

                    <b-form-group
                      :label="$t('banners.targetings.ip_addr')"
                      label-for="i-ip_addr"
                    >
                      <b-form-radio-group
                        v-model="ipAddrCampaign.status"
                        class="mb-1"
                        button-variant="outline-primary"
                        :options="optionsRadioLocale"
                        buttons
                        size="sm"
                        name="radios-btn-default"
                      />
                      <div>
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          size="sm"
                          class="mb-75 mr-75"
                          @click="$refs.refInputEl.$el.click()"
                        >
                          {{ $t('banners.targetings.choosecsv') }}
                        </b-button>
                        <b-form-file
                          ref="refInputEl"
                          v-model="importFile"
                          accept=".csv"
                          :hidden="true"
                          plain
                          @input="importCsv"
                        />
                        <p
                          v-if="campaign.ip_addr.params !== undefined"
                          class="card-text"
                        >
                          {{ campaign.ip_addr.params.filename }}
                        </p>
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    md="12"
                    class="d-flex align-items-center justify-content-end mt-1"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-secondary"
                      class="mr-0 mr-sm-1"
                      @click="$router.go(-1)"
                    >
                      <feather-icon
                        icon="ChevronLeftIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">{{ $t('form.back') }}</span>
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="campaignUpdate"
                    >
                      <span class="align-middle">{{ $t('form.save') }}</span>
                      <feather-icon
                        icon="ChevronRightIcon"
                        class="ml-50"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-tab>
            <b-tab v-else>
              <template #title>
                <feather-icon
                  icon="ToolIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">{{ $t('campaigns.extrasettings') }}</span>
              </template>
              <b-card>
                <div class="d-flex justify-content-between align-items-center mb-2">
                  <div>
                    <h4 class="mb-0">
                      {{ $t('campaigns.extrasettings') }}
                    </h4>
                  </div>
                  <feather-icon
                    icon="XIcon"
                    size="18"
                    class="cursor-pointer"
                    @click="$router.go(-1)"
                  />
                </div>
                <b-row>
                  <b-col md="6">
                    <b-form-group
                      :label="$t('campaigns.adomain')"
                      label-for="i-adomain"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="LayoutIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="i-adomain"
                          v-model="campaign.adomain"
                          :placeholder="$t('campaigns.adomain')"
                          autocomplete="adomain"
                        />
                      </b-input-group>
                    </b-form-group>
                    <b-form-group
                      :label="$t('banners.targetings.country')"
                      label-for="i-country"
                    >
                      <v-select
                        id="i-country"
                        v-model="country"
                        :placeholder="$t('banners.targetings-selects.country')"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="countries"
                        label="text"
                      />
                    </b-form-group>
                    <b-form-group
                      :label="$t('sites.category')"
                      label-for="i-category"
                    >
                      <b-form-select
                        id="i-category"
                        v-model="main_category"
                        :options="campaignCategory"
                        @change="changeCategory"
                      />
                    </b-form-group>

                    <b-form-group
                      :label="$t('campaigns.sub_category')"
                      label-for="i-subcategory"
                    >
                      <b-form-select
                        id="i-subcategory"
                        v-model="sub_category"
                        :options="campaignSubCategory[main_category]"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      :label="$t('banners.targetings.capping')"
                      label-for="i-capping"
                    >
                      <b-form-radio-group
                        v-model="campaign.capping.status"
                        class="mb-1"
                        button-variant="outline-primary"
                        :options="optionsRadioLocale"
                        buttons
                        size="sm"
                        name="radios-btn-default"
                      />
                      <b-input-group
                        class="input-group-merge"
                      >
                        <b-input-group-prepend is-text>
                          <feather-icon icon="ClockIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="i-capping"
                          v-model="cappingCampaign"
                          :placeholder="$t('banners.targetings.hours')"
                          type="number"
                        />
                      </b-input-group>
                    </b-form-group>
                    <b-form-group
                      :label="$t('banners.targetings.site_language')"
                      label-for="i-site_language"
                    >
                      <b-form-radio-group
                        v-model="campaign.site_language.status"
                        class="mb-1"
                        button-variant="outline-primary"
                        :options="optionsRadioLocale"
                        buttons
                        size="sm"
                        name="radios-btn-default"
                      />
                      <v-select
                        id="i-site_language"
                        v-model="siteLanguages"
                        :class="statusVariant(campaign.site_language.status)"
                        :placeholder="$t('banners.targetings-selects.language')"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="languages"
                        label="text"
                        multiple
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    md="12"
                    class="d-flex align-items-center justify-content-end mt-1"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-secondary"
                      class="mr-0 mr-sm-1"
                      @click="$router.go(-1)"
                    >
                      <feather-icon
                        icon="ChevronLeftIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">{{ $t('form.back') }}</span>
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="campaignUpdate"
                    >
                      <span class="align-middle">{{ $t('form.save') }}</span>
                      <feather-icon
                        icon="ChevronRightIcon"
                        class="ml-50"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-tab>
            <!--/ addittional tab -->
            <b-tab v-if="authUserData.role.includes('admin')">
              <!-- title -->
              <template #title>
                <feather-icon
                  icon="TargetIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">{{ $t('banners.targetings.title') }}</span>
              </template>
              <b-card>
                <div class="head-card">
                  <div class="head-card-top">
                    <h4>{{ $t('banners.targetings.title') }}</h4>
                    <feather-icon
                      v-b-tooltip.hover.top="$t('Close')"
                      icon="XIcon"
                      size="18"
                      class="toggle-close cursor-pointer"
                      @click="$router.go(-1)"
                    />
                  </div>
                  <hr>
                </div>
                <b-row>
                  <b-col
                    lg="4"
                    xl="4"
                  >
                    <b-card
                      border-variant="primary"
                      :title="$t('banners.targetings.date')"
                      bg-variant="transparent"
                      class="shadow-none"
                    >
                      <b-form-group>
                        <b-form-radio-group
                          v-model="targetings.date.status"
                          class="mb-1"
                          button-variant="outline-primary"
                          :options="optionsRadioLocale"
                          buttons
                          size="sm"
                          name="radios-btn-default"
                        />
                      </b-form-group>
                      <b-form-group
                        :label="$t('banners.targetings.startdate')"
                        for="i-startdate"
                      >
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                            <feather-icon
                              icon="CalendarIcon"
                              size="16"
                            />
                          </b-input-group-prepend>
                          <flat-pickr
                            id="i-startdate"
                            v-model="targetings.date.params.startdate"
                            :config="{
                              locale: flatPickrLocales[$i18n.locale],
                              altInput: true,
                              altFormat: 'd-m-Y',
                              dateFormat: 'Y-m-d',
                            }"
                            class="form-control flat-picker bg-transparent shadow-none"
                            placeholder="DD-MM-YYYY"
                          />
                        </b-input-group>
                      </b-form-group>
                      <b-form-group
                        :label="$t('banners.targetings.enddate')"
                        for="i-startdate"
                      >
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                            <feather-icon
                              icon="CalendarIcon"
                              size="16"
                            />
                          </b-input-group-prepend>
                          <flat-pickr
                            id="i-enddate"
                            v-model="targetings.date.params.enddate"
                            :config="{
                              locale: flatPickrLocales[$i18n.locale],
                              altInput: true,
                              altFormat: 'd-m-Y',
                              dateFormat: 'Y-m-d',
                            }"
                            class="form-control flat-picker bg-transparent shadow-none"
                            placeholder="DD-MM-YYYY"
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-card>
                  </b-col>
                  <b-col
                    lg="4"
                    xl="4"
                  >
                    <b-card
                      border-variant="primary"
                      :title="$t('banners.targetings.date_num.label')"
                      bg-variant="transparent"
                      class="shadow-none"
                    >
                      <b-form-group>
                        <b-form-radio-group
                          v-model="targetings.date_num.status"
                          class="mb-1"
                          button-variant="outline-primary"
                          :options="optionsRadioLocale"
                          buttons
                          size="sm"
                          name="radios-btn-default"
                        />
                      </b-form-group>

                      <b-form-group
                        for="i-date_num"
                      >
                        <flat-pickr
                          id="i-date_num"
                          v-model="targetings.date_num.params.dates"
                          :config="{
                            locale: flatPickrLocales[$i18n.locale],
                            mode: 'multiple',
                            inline: true,
                            dateFormat: 'Y-m-d',
                          }"
                          class="form-control flat-picker bg-transparent shadow-none"
                          placeholder="DD-MM-YYYY"
                        />
                      </b-form-group>
                    </b-card>
                  </b-col>
                  <b-col
                    lg="4"
                    xl="4"
                  >
                    <b-card
                      border-variant="primary"
                      :title="$t('banners.targetings.weekday')"
                      bg-variant="transparent"
                      class="shadow-none"
                    >
                      <b-form-group>
                        <b-form-radio-group
                          v-model="targetings.weekday.status"
                          class="mb-1"
                          button-variant="outline-primary"
                          :options="optionsRadioLocale"
                          buttons
                          size="sm"
                          name="radios-btn-default"
                        />
                        <v-select
                          id="i-weekday"
                          v-model="targetings.weekday.params.weekdays"
                          :class="statusVariant(targetings.weekday.status)"
                          :placeholder="$t('banners.targetings-selects.weekday')"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="weeks_days"
                          label="text"
                          multiple
                        />
                      </b-form-group>
                    </b-card>
                  </b-col>
                  <b-col
                    lg="4"
                    xl="4"
                  >
                    <b-card
                      border-variant="primary"
                      :title="$t('banners.targetings.hour')"
                      bg-variant="transparent"
                      class="shadow-none"
                    >
                      <b-form-group>
                        <b-form-radio-group
                          v-model="targetings.hour.status"
                          class="mb-1"
                          button-variant="outline-primary"
                          :options="optionsRadioLocale"
                          buttons
                          size="sm"
                          name="radios-btn-default"
                        />

                        <v-select
                          id="i-hour"
                          v-model="targetings.hour.params.hours"
                          :class="statusVariant(targetings.hour.status)"
                          :placeholder="$t('banners.targetings-selects.hour')"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="hours_nums"
                          label="text"
                          multiple
                        />
                      </b-form-group>
                    </b-card>
                  </b-col>
                  <b-col
                    lg="4"
                    xl="4"
                  >
                    <b-card
                      border-variant="primary"
                      :title="$t('banners.targetings.device')"
                      bg-variant="transparent"
                      class="shadow-none"
                    >
                      <b-form-group>
                        <b-form-radio-group
                          v-model="targetings.device.status"
                          class="mb-1"
                          button-variant="outline-primary"
                          :options="optionsRadioLocale"
                          buttons
                          size="sm"
                          name="radios-btn-default"
                        />
                        <v-select
                          id="i-devices"
                          v-model="targetings.device.params.devices"
                          :class="statusVariant(targetings.device.status)"
                          :placeholder="$t('banners.targetings-selects.device')"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="devices"
                          label="text"
                          multiple
                        />
                      </b-form-group>
                    </b-card>
                  </b-col>
                  <b-col
                    lg="4"
                    xl="4"
                  >
                    <b-card
                      border-variant="primary"
                      :title="$t('banners.targetings.os')"
                      bg-variant="transparent"
                      class="shadow-none"
                    >
                      <b-form-group>
                        <b-form-radio-group
                          v-model="targetings.os.status"
                          class="mb-1"
                          button-variant="outline-primary"
                          :options="optionsRadioLocale"
                          buttons
                          size="sm"
                          name="radios-btn-default"
                        />

                        <v-select
                          id="i-os"
                          v-model="targetings.os.params.os"
                          :class="statusVariant(targetings.os.status)"
                          :placeholder="$t('banners.targetings-selects.os')"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="os"
                          label="text"
                          multiple
                        />
                      </b-form-group>
                    </b-card>
                  </b-col>
                  <b-col
                    lg="4"
                    xl="4"
                  >
                    <b-card
                      border-variant="primary"
                      :title="$t('banners.targetings.browser')"
                      bg-variant="transparent"
                      class="shadow-none"
                    >
                      <b-form-group>
                        <b-form-radio-group
                          v-model="targetings.browser.status"
                          class="mb-1"
                          button-variant="outline-primary"
                          :options="optionsRadioLocale"
                          buttons
                          size="sm"
                          name="radios-btn-default"
                        />

                        <v-select
                          id="i-browser"
                          v-model="targetings.browser.params.browsers"
                          :class="statusVariant(targetings.browser.status)"
                          :placeholder="$t('banners.targetings-selects.browser')"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="browsers"
                          label="text"
                          multiple
                        />
                      </b-form-group>
                    </b-card>
                  </b-col>
                  <b-col
                    lg="4"
                    xl="4"
                  >
                    <b-card
                      border-variant="primary"
                      :title="$t('banners.targetings.language')"
                      bg-variant="transparent"
                      class="shadow-none"
                    >
                      <b-form-group>
                        <b-form-radio-group
                          v-model="targetings.language.status"
                          class="mb-1"
                          button-variant="outline-primary"
                          :options="optionsRadioLocale"
                          buttons
                          size="sm"
                          name="radios-btn-default"
                        />

                        <v-select
                          id="i-language"
                          v-model="targetings.language.params.languages"
                          :class="statusVariant(targetings.language.status)"
                          :placeholder="$t('sites.chooselanguage')"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="langcode"
                          label="text"
                          multiple
                        />
                      </b-form-group>
                    </b-card>
                  </b-col>
                  <b-col
                    lg="4"
                    xl="4"
                  >
                    <b-card
                      border-variant="primary"
                      :title="$t('banners.targetings.country')"
                      bg-variant="transparent"
                      class="shadow-none"
                    >
                      <b-form-group>
                        <b-form-radio-group
                          v-model="targetings.country.status"
                          class="mb-1"
                          button-variant="outline-primary"
                          :options="optionsRadioLocale"
                          buttons
                          size="sm"
                          name="radios-btn-default"
                        />

                        <v-select
                          id="i-countries"
                          v-model="targetings.country.params.countries"
                          :class="statusVariant(targetings.country.status)"
                          :placeholder="$t('sites.choosecountry')"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="countriesAllFormated"
                          label="text"
                          multiple
                        />
                      </b-form-group>
                    </b-card>
                  </b-col>
                  <b-col
                    lg="4"
                    xl="4"
                  >
                    <b-card
                      border-variant="primary"
                      :title="$t('banners.targetings.city')"
                      bg-variant="transparent"
                      class="shadow-none"
                    >
                      <b-form-group>
                        <b-form-radio-group
                          v-model="targetings.city.status"
                          class="mb-1"
                          button-variant="outline-primary"
                          :options="optionsRadioLocale"
                          buttons
                          size="sm"
                          name="radios-btn-default"
                        />
                        <v-select
                          id="i-language"
                          v-model="targetings.city.params.cities"
                          :class="statusVariant(targetings.city.status)"
                          :placeholder="$t('banners.targetings-selects.city')"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="citiesCountry"
                          label="text"
                          multiple
                        />
                      </b-form-group>
                    </b-card>
                  </b-col>
                  <b-col
                    lg="4"
                    xl="4"
                  >
                    <b-card
                      border-variant="primary"
                      :title="$t('banners.targetings.sites')"
                      bg-variant="transparent"
                      class="shadow-none"
                    >
                      <b-form-group
                        label-for="i-sites"
                      >
                        <b-form-radio-group
                          v-model="targetings.site.status"
                          class="mb-1"
                          :class="statusVariant(targetings.site.status)"
                          button-variant="outline-primary"
                          :options="optionsRadioLocale"
                          buttons
                          size="sm"
                          name="radios-btn-default"
                        />
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="HashIcon" />
                          </b-input-group-prepend>
                          <b-form-textarea
                            id="i-sites"
                            v-model="targetings.site.params.sites"
                            placeholder="Ids"
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-card>
                  </b-col>
                  <b-col
                    lg="4"
                    xl="4"
                  >
                    <b-card
                      border-variant="primary"
                      :title="$t('banners.targetings.widget_type')"
                      bg-variant="transparent"
                      class="shadow-none"
                    >
                      <b-form-group>
                        <b-form-radio-group
                          v-model="targetings.widget_type.status"
                          class="mb-1"
                          button-variant="outline-primary"
                          :options="optionsRadioLocale"
                          buttons
                          size="sm"
                          name="radios-btn-default"
                        />
                        <v-select
                          id="i-language"
                          v-model="targetings.widget_type.params.widget_types"
                          :class="statusVariant(targetings.widget_type.status)"
                          :placeholder="$t('banners.targetings-selects.widget_type')"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="widgetTypeLocale"
                          label="text"
                          multiple
                        />
                      </b-form-group>
                    </b-card>
                  </b-col>
                  <b-col
                    lg="4"
                    xl="4"
                  >
                    <b-card
                      border-variant="primary"
                      title="Interstitial"
                      bg-variant="transparent"
                      class="shadow-none"
                    >
                      <b-form-group>
                        <b-form-radio-group
                          v-model="campaign.targetings.widget_interstitial.status"
                          class="mb-1"
                          button-variant="outline-primary"
                          :options="optionsRadioLocale"
                          buttons
                          size="sm"
                          name="radios-btn-default"
                        />
                        <div v-if="campaign.targetings.widget_interstitial.params.interstitial">
                          <div class="alert alert-success mb-0">
                            <div class="alert-body">
                              {{ $t('selects.statuses.active') }}
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          <div class="alert alert-warning mb-0">
                            <div class="alert-body">
                              {{ $t('selects.statuses.inactive') }}
                            </div>
                          </div>
                        </div>
                      </b-form-group>
                    </b-card>
                  </b-col>
                  <b-col
                    lg="4"
                    xl="4"
                  >
                    <b-card
                      border-variant="primary"
                      :title="$t('banners.targetings.pixel_targeting')"
                      bg-variant="transparent"
                      class="shadow-none"
                    >
                      <b-form-group
                        label-for="i-remarketing"
                      >
                        <b-form-radio-group
                          v-model="targetings.pixel_targeting.status"
                          class="mb-1"
                          :class="statusVariant(targetings.pixel_targeting.status)"
                          button-variant="outline-primary"
                          :options="optionsRadioLocale"
                          buttons
                          size="sm"
                          name="radios-btn-default"
                        />
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="Link2Icon" />
                          </b-input-group-prepend>
                          <b-form-textarea
                            id="i-pixel_targeting"
                            v-model="targetings.pixel_targeting.params.urls"
                            placeholder="Urls"
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-card>
                  </b-col>
                  <b-col
                    lg="4"
                    xl="4"
                  >
                    <b-card
                      border-variant="primary"
                      :title="$t('banners.targetings.capping')"
                      bg-variant="transparent"
                      class="shadow-none"
                    >
                      <b-form-group>
                        <b-form-radio-group
                          v-model="targetings.capping.status"
                          class="mb-1"
                          :class="statusVariant(targetings.capping.status)"
                          button-variant="outline-primary"
                          :options="optionsRadioLocale"
                          buttons
                          size="sm"
                          name="radios-btn-default"
                        />

                        <b-input-group
                          class="input-group-merge"
                        >
                          <b-input-group-prepend is-text>
                            <feather-icon icon="ClockIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="i-capping"
                            v-model="targetings.capping.params.hours"
                            :placeholder="$t('banners.targetings.hours')"
                            type="number"
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-card>
                  </b-col>
                  <b-col
                    lg="4"
                    xl="4"
                  >
                    <b-card
                      border-variant="primary"
                      :title="$t('banners.targetings.site_language')"
                      bg-variant="transparent"
                      class="shadow-none"
                    >
                      <b-form-group>
                        <b-form-radio-group
                          v-model="targetings.site_language.status"
                          class="mb-1"
                          button-variant="outline-primary"
                          :options="optionsRadioLocale"
                          buttons
                          size="sm"
                          name="radios-btn-default"
                        />
                        <v-select
                          id="i-site_language"
                          v-model="targetings.site_language.params.languages"
                          :class="statusVariant(targetings.site_language.status)"
                          :placeholder="$t('sites.chooselanguage')"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="languages"
                          label="text"
                          multiple
                        />
                      </b-form-group>
                    </b-card>
                  </b-col>
                  <b-col
                    v-if="authUserData.role.includes('admin')"
                    lg="4"
                    xl="4"
                  >
                    <b-card
                      border-variant="primary"
                      :title="$t('DSP partners')"
                      bg-variant="transparent"
                      class="shadow-none"
                    >
                      <b-form-group>
                        <b-form-radio-group
                          v-model="targetings.dsppartner.status"
                          class="mb-1"
                          :class="statusVariant(targetings.dsppartner.status)"
                          button-variant="outline-primary"
                          :options="optionsRadioLocale"
                          buttons
                          size="sm"
                          name="radios-btn-default"
                        />
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="HashIcon" />
                          </b-input-group-prepend>
                          <b-form-textarea
                            id="i-dsppartner"
                            v-model="targetings.dsppartner.params.dsppartners"
                            placeholder="DSP partner ID"
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-card>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    md="12"
                    class="d-flex align-items-center justify-content-end mt-1"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-secondary"
                      class="mr-0 mr-sm-1"
                      @click="$router.go(-1)"
                    >
                      <feather-icon
                        icon="ChevronLeftIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">{{ $t('form.back') }}</span>
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="campaignUpdate"
                    >
                      <span class="align-middle">{{ $t('form.save') }}</span>
                      <feather-icon
                        icon="ChevronRightIcon"
                        class="ml-50"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-tab>
            <!--/ targetings tab -->
          </b-tabs>
        </validation-observer>
      </b-form>
    </div>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import options from '@/libs/options'
import vSelect from 'vue-select'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  BAlert,
  BRow,
  BCol,
  BTabs,
  BTab,
  BCard,
  BForm,
  BFormGroup,
  BFormRadioGroup,
  // BInputGroupAppend,
  BInputGroupPrepend,
  BInputGroup,
  BFormInput,
  BFormFile,
  BSpinner,
  VBTooltip,
  BFormSelect,
  BFormCheckbox,
  BButton,
  BFormTextarea,
  BFormDatepicker,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
  extend,
} from 'vee-validate'
import {
  required,
  integer,
  min,
} from '@validations'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import UserToolbar from '@/views/dashboard/users/UserToolbar.vue'
import CampaignToolbar from '@/views/dashboard/campaigns/CampaignToolbar.vue'
import getFormattedDate, { applyTimeZoneOffset } from '@/utils/date-formatter'
import { getUserData } from '@/auth/utils'
import ClassicEditor from '@/libs/ckeditor5/build/ckeditor'
import { uploader } from '@/utils/upload-adapter'
import flatPickr from 'vue-flatpickr-component'
import {
  Ukrainian,
  Russian,
  English,
  Portuguese,
} from '@core/libs/flatpickr/l10n/locales'
import langcode from '@/libs/langcode'
import countriesAll from '@/libs/countries.json'

export default {
  components: {
    flatPickr,
    BAlert,
    BRow,
    BCol,
    BTabs,
    BTab,
    BCard,
    Breadcrumbs,
    BForm,
    BFormGroup,
    BFormRadioGroup,
    // BInputGroupAppend,
    BInputGroupPrepend,
    BInputGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    BButton,
    BFormTextarea,
    vSelect,
    AppCollapse,
    AppCollapseItem,
    BFormDatepicker,
    UserToolbar,
    CampaignToolbar,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        extraPlugins: [uploader],
      },
      authUserData: getUserData(),
      pageTitle: '',
      pageSubtitle: '',
      account: {},
      min,
      required,
      integer,
      widgets: [],
      widgetsCampaign: [],
      countryCampaign: {
        status: 'inactive',
      },
      ipAddrCampaign: {
        status: 'inactive',
      },
      remarketing: {
        processor: 'core.targetings.ReMarketingTargeting',
        params: {
          campaigns: [],
        },
        status: 'inactive',
      },
      countriesCampaign: [],
      cappingCampaign: null,
      siteLanguages: [],
      accountCampaings: [],
      campaign: [],
      breadcrumbItems: [],
      status: {},
      country: {},
      selectedType: {},
      main_category: 'IAB24',
      sub_category: 'IAB24',
      site_id: '',
      site_url: '',
      api_url: '',
      importFile: null,
      cpc_feed_api_processor: '',
      startdate: null,
      enddate: null,
      use_auto: false,
      is_moderate: false,
      impressionSizeAlert: 0,
      enable_openrtb: false,
      only_openrtb: false,
      hide_clicks_for_sites: false,
      on_image_load: false,
      ecpm_bid: 0,
      ...options,
      countriesAll,
      langcode,
      optionsRadio: [
        { text: 'Inactive', value: 'inactive' },
        { text: 'Active', value: 'active' },
        { text: 'Negative', value: 'negative' },
      ],
      cpcFeedApiProcessor: [
        { text: 'MGID Feed', value: 'core.services.base.MGIDFeedAPI' },
        { text: 'MGID Feed v2', value: 'core.services.base.MGIDFeedAPIv2' },
        { text: 'DaoPush', value: 'core.services.base.DaoPushAPI' },
        { text: 'RevContent', value: 'core.services.base.RevContentFeedAPI' },
        { text: 'ContentAd', value: 'core.services.base.ContentAdFeedAPI' },
        { text: 'Mobitech', value: 'core.services.base.MobitechFeedAPI' },
        { text: 'Mondiad', value: 'core.services.base.MondiadFeedAPI' },
        { text: 'Adscompass', value: 'core.services.base.AdsCompassFeedAPI' },
        { text: 'AdRight', value: 'core.services.base.AdRightFeedAPI' },
      ],
      flatPickrLocales: {
        en: English,
        ru: Russian,
        ua: Ukrainian,
        pt: Portuguese,
      },
      targetings: {
        date: {
          status: 'inactive',
          params: {
            startdate: null,
            enddate: null,
          },
          processor: 'core.targetings.DateTargeting',
        },
        date_num: {
          status: 'inactive',
          params: {
            dates: [],
          },
          processor: 'core.targetings.DateNumTargeting',
        },
        weekday: {
          status: 'inactive',
          params: {
            weekdays: null,
          },
          processor: 'core.targetings.WeekTargeting',
        },
        hour: {
          params: {
            hours: null,
          },
          status: 'inactive',
          processor: 'core.targetings.HourTargeting',
        },
        device: {
          params: {
            devices: null,
          },
          status: 'inactive',
          processor: 'core.targetings.DeviceTargeting',
        },
        country: {
          params: {
            countries: null,
          },
          status: 'inactive',
          processor: 'core.targetings.CountryTargeting',
        },
        site: {
          params: {
            sites: null,
          },
          status: 'inactive',
          processor: 'core.targetings.SiteTargeting',
        },
        widget_type: {
          processor: 'core.targetings.WidgetTypeTargeting',
          params: {
            widget_types: null,
          },
          status: 'inactive',
        },
        widget_interstitial: {
          processor: 'core.targetings.WidgetInterstitialTargeting',
          params: {
            interstitial: false,
          },
          status: 'inactive',
        },
        time: {
          params: {
            endtime: '',
            starttime: '',
          },
          status: 'inactive',
          processor: 'core.targetings.TimeTargeting',
        },
        site_language: {
          params: {
            languages: null,
          },
          status: 'inactive',
          processor: 'core.targetings.SiteLanguageTargeting',
        },
        city: {
          params: {
            cities: null,
          },
          status: 'inactive',
          processor: 'core.targetings.CityTargeting',
        },
        os: {
          params: {
            os: null,
          },
          status: 'inactive',
          processor: 'core.targetings.OsTargeting',
        },
        subscribe: {
          params: {
            sites: [],
          },
          status: 'inactive',
          processor: 'core.targetings.SubscribeTargeting',
        },
        language: {
          params: {
            languages: null,
          },
          status: 'inactive',
          processor: 'core.targetings.LanguageTargeting',
        },
        pixel_targeting: {
          params: {
            urls: null,
          },
          status: 'inactive',
          processor: 'core.targetings.PixelTargeting',
        },
        capping: {
          params: {
            hours: null,
          },
          status: 'inactive',
          processor: 'core.targetings.CappingTargeting',
        },
        browser: {
          params: {
            browsers: null,
          },
          status: 'inactive',
          processor: 'core.targetings.BrowserTargeting',
        },
        dsppartner: {
          processor: 'core.targetings.DSPPartnerTargeting',
          params: {
            dsppartners: null,
          },
          status: 'inactive',
        },
      },
      applyTimeZoneOffset,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        negative     : 'outline-primary',
        active       : 'outline-success',
        inactive     : 'outline-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    countriesAllFormated() {
      const formated = Object.keys(this.countriesAll).map(c => ({ text: c, value: c, cities: this.countriesAll[c] }))
      return formated
    },
    citiesCountry() {
      let allCities = []
      if (this.targetings.country.params.countries !== null && this.targetings.country.params.countries.length !== 0) {
        this.targetings.country.params.countries.forEach(c => {
          if (this.countriesAll[c.value] !== undefined) {
            const cities = this.countriesAll[c.value].map(cc => ({ text: cc, value: cc }))
            allCities.push(...cities)
          }
        })
        allCities = allCities.filter((item, index, self) => index === self.findIndex(obj => obj.value === item.value)) // remove duplicates
      } else {
        allCities = []
      }
      return allCities
    },
    statusesLocale() {
      return this.statuses.map(s => ({
        value: s.value,
        text: this.$t(`selects.statuses.${s.value}`),
      }))
    },
    clickfilterLocale() {
      return this.clickfilter.map(c => {
        const splitValue = c.value.split('.')
        const translateKey = splitValue[splitValue.length - 1]
        return {
          value: c.value,
          text: this.$t(`selects.clickfilters.${translateKey.toLowerCase()}`),
        }
      })
    },
    campaignTypeLocale() {
      return this.campaignType.map(t => ({
        value: t.value,
        text: this.$t(`selects.adstype.${t.value}`),
      })).filter(ct => ct.value !== 'postback')
    },
    widgetTypeLocale() {
      return this.widgetTypes.map(t => ({
        value: t.value,
        text: this.$t(`selects.widgettype.${t.value}`),
      }))
    },
    optionsRadioLocale() {
      return this.optionsRadio.map(o => ({
        value: o.value,
        text: this.$t(`banners.targetings-selects.${o.value}`),
      }))
    },
    uniqError() {
      return this.$t('campaigns.campaigntitleunique')
    },
  },
  watch: {
    'campaign.targetings.widget_interstitial.status': {
      handler(newVal) {
        this.campaign.targetings.widget_interstitial.params.interstitial = newVal !== 'inactive'
      },
    },
  },
  created() {
    this.getCampaignData()
    if (this.authUserData.role.includes('admin')) {
      this.getWidgets()
    }
    extend('unique', value => {
      const campaigns = this.accountCampaings.filter(ac => ac._id !== this.$route.params.id)
      const existTitle = campaigns.find(c => c.title === value)
      if (!existTitle) {
        return true
      }
      return this.uniqError
    })
  },
  methods: {
    store() {},
    async importCsv() {
      this.campaign.ip_addr.params.filename = this.importFile.name
      if (this.importFile) {
        const formData = new FormData()
        formData.append('files', this.$refs.refInputEl.files[0])
        const response = await useJwt.importIps(this.$route.params.id, formData)
        if (response.data) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('swal.success'),
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        }
      }
    },
    async getCampaignData() {
      const responseData = await useJwt.getCampaign(this.$route.params.id)
      this.campaign = responseData.data.campaign || {}
      if (this.campaign.status !== '') {
        const statusText = this.campaign.status.charAt(0).toUpperCase() + this.campaign.status.slice(1)
        this.status = { value: this.campaign.status, text: statusText }
      }

      if (Object.keys(this.campaign.ip_addr).length) {
        this.ipAddrCampaign.status = this.campaign.ip_addr.status
      }

      this.use_auto = this.campaign.use_auto
      this.is_moderate = this.campaign.is_moderate

      this.startdate = this.campaign.startdate !== null && this.campaign.startdate !== '' ? getFormattedDate(new Date(this.campaign.startdate)) : null
      this.enddate = this.campaign.enddate !== null && this.campaign.enddate !== '' ? getFormattedDate(new Date(this.campaign.enddate)) : null

      if (this.campaign.country_name !== '' && this.campaign.country_name !== null) {
        const countryText = this.campaign.country_name.charAt(0).toUpperCase() + this.campaign.country_name.slice(1)
        this.country = { value: this.campaign.country_name, text: countryText }
      } else {
        this.country = null
      }

      const { languages } = this.campaign.site_language.params
      if (languages.length !== 0) {
        this.siteLanguages = languages.map(l => ({ value: l, text: l }))
      }

      if (Object.keys(this.campaign.capping).length !== 0) {
        const { hours } = this.campaign.capping.params
        if (hours !== '') {
          this.cappingCampaign = hours
        }
      }
      if (Object.keys(this.campaign.remarketing).length !== 0) {
        const { campaigns } = this.campaign.remarketing.params
        if (campaigns.length) {
          this.remarketing.params.campaigns = campaigns.join(',')
        } else {
          this.remarketing.params.campaigns = null
        }
      }
      this.setTargetings()
      this.getAccountCompaigns()
      this.getUserData()
    },
    setTargetings() {
      if (this.campaign.targetings.date !== undefined) {
        if (this.campaign.targetings.date.status !== undefined && this.campaign.targetings.date.status !== null) {
          this.targetings.date.status = this.campaign.targetings.date.status
        }

        if (this.campaign.targetings.date.params !== undefined) {
          this.targetings.date.params.startdate = this.campaign.targetings.date.params.startdate !== undefined ? new Date(this.campaign.targetings.date.params.startdate) : null
          this.targetings.date.params.enddate = this.campaign.targetings.date.params.enddate !== undefined ? new Date(this.campaign.targetings.date.params.enddate) : null
        }
      }

      if (this.campaign.targetings.date_num !== undefined) {
        this.targetings.date_num.status = this.campaign.targetings.date_num.status
        const { dates } = this.campaign.targetings.date_num.params
        if (dates.length !== 0) {
          this.targetings.date_num.params.dates = dates.map(dn => new Date(dn).toISOString()).join(', ')
        }
      }

      if (this.campaign.targetings.weekday !== undefined) {
        this.targetings.weekday.status = this.campaign.targetings.weekday.status
        const { weekdays } = this.campaign.targetings.weekday.params
        if (weekdays.length !== 0) {
          this.targetings.weekday.params.weekdays = weekdays.map(w => ({ value: w, text: w }))
        }
      }

      if (this.campaign.targetings.widget_type !== undefined) {
        this.targetings.widget_type.status = this.campaign.targetings.widget_type.status || 'inactive'
        const { widget_types } = this.campaign.targetings.widget_type.params
        if (widget_types.length !== 0) {
          this.targetings.widget_type.params.widget_types = widget_types.map(w => ({ value: w, text: w }))
        }
      }

      if (!this.campaign.targetings?.widget_interstitial) {
        this.campaign.targetings.widget_interstitial = this.targetings.widget_interstitial
      }

      if (this.campaign.targetings.hour !== undefined) {
        this.targetings.hour.status = this.campaign.targetings.hour.status
        const { hours } = this.campaign.targetings.hour.params
        if (hours.length !== 0) {
          const hoursNormalize = this.applyTimeZoneOffset(hours, true)
          this.targetings.hour.params.hours = hoursNormalize.map(h => ({ value: h, text: h }))
        }
      }

      if (this.campaign.targetings.device !== undefined) {
        this.targetings.device.status = this.campaign.targetings.device.status
        const { devices } = this.campaign.targetings.device.params
        if (devices.length !== 0) {
          this.targetings.device.params.devices = devices.map(h => ({ value: h, text: h }))
        }
      }

      if (this.campaign.targetings.os !== undefined) {
        this.targetings.os.status = this.campaign.targetings.os.status
        const { os } = this.campaign.targetings.os.params
        if (os.length !== 0) {
          this.targetings.os.params.os = os.map(h => ({ value: h, text: h }))
        }
      }

      if (this.campaign.targetings.browser !== undefined) {
        this.targetings.browser.status = this.campaign.targetings.browser.status
        const { browsers } = this.campaign.targetings.browser.params
        if (browsers.length !== 0) {
          this.targetings.browser.params.browsers = browsers.map(h => ({ value: h, text: h }))
        }
      }

      if (this.campaign.targetings.language !== undefined) {
        this.targetings.language.status = this.campaign.targetings.language.status
        const { languages } = this.campaign.targetings.language.params
        if (languages.length !== 0) {
          this.targetings.language.params.languages = languages.map(h => (this.langcode.find(i => i.value === h)))
        }
      }

      if (this.campaign.targetings.language !== undefined) {
        this.targetings.language.status = this.campaign.targetings.language.status
        const { sites } = this.campaign.targetings.site.params
        if (sites.length !== 0) {
          this.targetings.site.params.sites = sites.join(',')
        } else {
          this.targetings.site.params.sites = null
        }
      }

      if (this.campaign.targetings.site !== undefined) {
        this.targetings.site.status = this.campaign.targetings.site.status
      }

      if (this.campaign.targetings.dsppartner !== undefined) {
        this.targetings.dsppartner.status = this.campaign.targetings.dsppartner.status
        const { dsppartners } = this.campaign.targetings.dsppartner.params
        if (dsppartners.length !== 0) {
          this.targetings.dsppartner.params.dsppartners = dsppartners.join(',')
        } else {
          this.targetings.dsppartner.params.dsppartners = null
        }
      }

      if (this.campaign.targetings.capping !== undefined) {
        this.targetings.capping.status = this.campaign.targetings.capping.status
        const capping = this.campaign.targetings.capping.params.hours
        if (capping !== '') {
          this.targetings.capping.params.hours = capping
        }
      }

      if (this.campaign.targetings.site_language !== undefined) {
        this.targetings.site_language.status = this.campaign.targetings.site_language.status
        const siteLanguages = this.campaign.targetings.site_language.params.languages
        if (siteLanguages.length !== 0) {
          this.targetings.site_language.params.languages = siteLanguages.map(h => ({ value: h, text: h }))
        }
      }

      if (this.campaign.targetings.country !== undefined) {
        this.targetings.country.status = this.campaign.targetings.country.status
        const { countries } = this.campaign.targetings.country.params
        if (countries.length !== 0) {
          this.targetings.country.params.countries = countries.map(c => ({ value: c, text: c }))
        }
      }

      if (this.campaign.targetings.city !== undefined) {
        this.targetings.city.status = this.campaign.targetings.city.status
        const { cities } = this.campaign.targetings.city.params
        if (cities.length !== 0) {
          this.targetings.city.params.cities = cities.map(c => ({ value: c, text: c }))
        }
      }

      if (this.campaign.targetings.pixel_targeting !== undefined && this.campaign.targetings.pixel_targeting.params.urls !== '') {
        this.targetings.pixel_targeting.params.urls = this.campaign.targetings.pixel_targeting.params.urls.split(',').map(pixel => (pixel.trim()))
      } else {
        this.targetings.pixel_targeting.params.urls = null
      }
    },
    updateTargetings() {
      if (this.campaign.targetings.date === undefined) {
        this.campaign.targetings = Object.assign(this.campaign.targetings, this.targetings)
      }

      if (this.campaign.targetings.widget_type === undefined) {
        this.campaign.targetings.widget_type = this.targetings.widget_type
      }
      // statuses
      this.campaign.targetings.date.status = this.targetings.date.status
      this.campaign.targetings.date_num.status = this.targetings.date_num.status
      this.campaign.targetings.weekday.status = this.targetings.weekday.status
      this.campaign.targetings.widget_type.status = this.targetings.widget_type.status
      this.campaign.targetings.hour.status = this.targetings.hour.status
      this.campaign.targetings.device.status = this.targetings.device.status
      this.campaign.targetings.os.status = this.targetings.os.status
      this.campaign.targetings.browser.status = this.targetings.browser.status
      this.campaign.targetings.language.status = this.targetings.language.status
      this.campaign.targetings.country.status = this.targetings.country.status
      this.campaign.targetings.city.status = this.targetings.city.status
      this.campaign.targetings.site.status = this.targetings.site.status
      this.campaign.targetings.site_language.status = this.targetings.site_language.status
      this.campaign.targetings.capping.status = this.targetings.capping.status
      this.campaign.targetings.dsppartner.status = this.targetings.dsppartner.status
      this.campaign.targetings.pixel_targeting.status = this.targetings.pixel_targeting.status

      // processor pixel_targeting
      this.campaign.targetings.date.processor = this.targetings.date.processor
      this.campaign.targetings.date_num.processor = this.targetings.date_num.processor
      this.campaign.targetings.weekday.processor = this.targetings.weekday.processor
      this.campaign.targetings.widget_type.processor = this.targetings.widget_type.processor
      this.campaign.targetings.hour.processor = this.targetings.hour.processor
      this.campaign.targetings.device.processor = this.targetings.device.processor
      this.campaign.targetings.os.processor = this.targetings.os.processor
      this.campaign.targetings.browser.processor = this.targetings.browser.processor
      this.campaign.targetings.language.processor = this.targetings.language.processor
      this.campaign.targetings.country.processor = this.targetings.country.processor
      this.campaign.targetings.city.processor = this.targetings.city.processor
      this.campaign.targetings.site.processor = this.targetings.site.processor
      this.campaign.targetings.site_language.processor = this.targetings.site_language.processor
      this.campaign.targetings.capping.processor = this.targetings.capping.processor
      this.campaign.targetings.dsppartner.processor = this.targetings.dsppartner.processor
      this.campaign.targetings.pixel_targeting.processor = this.targetings.pixel_targeting.processor

      // values
      if (this.campaign.targetings.date !== undefined) {
        this.campaign.targetings.date.params.startdate = this.targetings.date.params.startdate !== null && this.targetings.date.params.startdate !== '' ? new Date(this.targetings.date.params.startdate).toISOString() : new Date().toISOString()
        this.campaign.targetings.date.params.enddate = this.targetings.date.params.enddate !== null && this.targetings.date.params.enddate !== '' ? new Date(this.targetings.date.params.enddate).toISOString() : new Date().toISOString()
      }

      if (this.targetings.date_num.params.dates.length !== 0) {
        this.campaign.targetings.date_num.params.dates = this.targetings.date_num.params.dates.split(',').map(dn => new Date(dn.trim()).toISOString())
      }

      if (this.targetings.weekday.params.weekdays !== null && this.targetings.weekday.params.weekdays.length !== 0) {
        this.campaign.targetings.weekday.params.weekdays = this.targetings.weekday.params.weekdays.map(w => w.text)
      }

      if (this.targetings.widget_type.params.widget_types !== null && this.targetings.widget_type.params.widget_types.length !== 0) {
        this.campaign.targetings.widget_type.params.widget_types = this.targetings.widget_type.params.widget_types.map(w => w.text)
      }

      if (this.targetings.hour.params.hours !== null && this.targetings.hour.params.hours.length !== 0) {
        this.campaign.targetings.hour.params.hours = this.applyTimeZoneOffset(this.targetings.hour.params.hours.map(h => Number(h.text)), false)
      }

      if (this.targetings.device.params.devices !== null && this.targetings.device.params.devices.length !== 0) {
        this.campaign.targetings.device.params.devices = this.targetings.device.params.devices.map(h => h.text)
      } else {
        this.campaign.targetings.device.params.devices = null
      }

      if (this.targetings.os.params.os !== null && this.targetings.os.params.os.length !== 0) {
        this.campaign.targetings.os.params.os = this.targetings.os.params.os.map(h => h.text)
      } else {
        this.campaign.targetings.os.params.os = null
      }

      if (this.targetings.browser.params.browsers !== null && this.targetings.browser.params.browsers.length !== 0) {
        this.campaign.targetings.browser.params.browsers = this.targetings.browser.params.browsers.map(h => h.text)
      } else {
        this.campaign.targetings.browser.params.browsers = null
      }

      if (this.targetings.language.params.languages !== null && this.targetings.language.params.languages.length !== 0) {
        this.campaign.targetings.language.params.languages = this.targetings.language.params.languages.map(h => h.value)
      } else {
        this.campaign.targetings.language.params.languages = null
      }

      if (this.targetings.country.params.countries !== null && this.targetings.country.params.countries.length !== 0) {
        this.campaign.targetings.country.params.countries = this.targetings.country.params.countries.map(c => c.text)
      } else {
        this.campaign.targetings.country.params.countries = null
      }

      if (this.targetings.city.params.cities !== null && this.targetings.city.params.cities.length !== 0) {
        this.campaign.targetings.city.params.cities = this.targetings.city.params.cities.map(c => c.text)
      } else {
        this.campaign.targetings.city.params.cities = null
      }

      if (this.targetings.site.params.sites !== null && this.targetings.site.params.sites.length !== 0) {
        this.campaign.targetings.site.params.sites = this.targetings.site.params.sites.split(',').map(s => (s.trim()))
      } else {
        this.campaign.targetings.site.params.sites = null
      }

      if (this.targetings.pixel_targeting.params.urls !== null && this.targetings.pixel_targeting.params.urls !== '') {
        this.campaign.targetings.pixel_targeting.params.urls = this.targetings.pixel_targeting.params.urls.split(',').map(pixel => (pixel.trim()))
      } else {
        this.campaign.targetings.pixel_targeting.params.urls = null
      }

      if (this.targetings.dsppartner.params.dsppartners !== null && this.targetings.dsppartner.params.dsppartners !== '') {
        this.campaign.targetings.dsppartner.params.dsppartners = this.targetings.dsppartner.params.dsppartners.split(',').map(dsp => (dsp.trim()))
      } else {
        this.campaign.targetings.dsppartner.params.dsppartners = null
      }

      this.campaign.targetings.capping.params.hours = this.targetings.capping.params.hours || null

      if (this.targetings.site_language.params.languages !== null && this.targetings.site_language.params.languages.length !== 0) {
        this.campaign.targetings.site_language.params.languages = this.targetings.site_language.params.languages.map(l => l.text)
      } else {
        this.campaign.targetings.site_language.params.languages = null
      }
    },
    campaignUpdate() {
      this.$refs.validationCampaignRules.validate().then(success => {
        if (success) {
          this.campaign.startdate = this.startdate !== null && this.startdate !== '' ? new Date(this.startdate).toISOString() : null
          this.campaign.enddate = this.enddate !== null && this.enddate !== '' ? new Date(this.enddate).toISOString() : null
          this.campaign.use_auto = this.use_auto
          this.campaign.is_moderate = this.is_moderate
          this.campaign.impression_size_alert = this.impressionSizeAlert
          this.campaign.enable_openrtb = this.enable_openrtb
          this.campaign.only_openrtb = this.only_openrtb
          this.campaign.hide_clicks_for_sites = this.hide_clicks_for_sites
          this.campaign.stats_settings = { on_image_load: this.on_image_load }
          this.campaign.ecpm_bid = this.ecpm_bid
          this.campaign.country_name = this.country !== null && Object.keys(this.country).length !== 0 ? this.country.value : ''
          this.campaign.type = this.selectedType
          this.campaign.main_category = this.main_category
          this.campaign.sub_category = this.sub_category
          this.campaign.widgets = this.widgetsCampaign.map(w => w.value)
          this.campaign.country = this.countryCampaign
          if (this.countriesCampaign.length !== 0) {
            this.campaign.country.params.countries = this.countriesCampaign.map(c => c.text)
          }
          if (this.authUserData.role.includes('admin')) {
            this.campaign.ip_addr.status = this.ipAddrCampaign.status
          }
          this.campaign.api_url = this.api_url
          this.campaign.cpc_feed_api_processor = this.cpc_feed_api_processor
          this.campaign.site_language.params.languages = this.siteLanguages.map(l => l.text)
          this.campaign.capping.params.hours = this.cappingCampaign || null
          if (this.remarketing.params.campaigns !== null && this.remarketing.params.campaigns.length !== 0) {
            this.campaign.remarketing.params.campaigns = this.remarketing.params.campaigns.split(',').map(c => (c.trim()))
          }
          this.updateTargetings()
          useJwt.setCampaign(this.$route.params.id, { campaign: this.campaign })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t('campaigns.campaign_saved'),
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              // this.$router.push({ name: 'dashboard-user-campaigns' })
            })
            .catch(error => {
              if (error.response && error.response.status === 422) {
                if (error.response.data.errors.length) {
                  error.response.data.errors.forEach(err => {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: 'Error',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text: err.detail,
                      },
                    })
                  })
                }
              }
            })
        }
      })
    },
    async getAccountCompaigns() {
      const responseData = await useJwt.accountCampaigns(this.$route.params.userId)
      this.accountCampaings = responseData.data.campaigns || []
      const currentCampaign = this.accountCampaings.find(ac => ac._id === this.$route.params.id)
      this.impressionSizeAlert = currentCampaign.impression_size_alert
      this.enable_openrtb = currentCampaign.enable_openrtb
      this.only_openrtb = currentCampaign.only_openrtb
      this.hide_clicks_for_sites = currentCampaign.hide_clicks_for_sites
      this.on_image_load = currentCampaign.stats_settings ? currentCampaign.stats_settings.on_image_load : false
      this.ecpm_bid = currentCampaign.ecpm_bid
      this.selectedType = currentCampaign.type
      if (currentCampaign.main_category !== undefined) {
        this.main_category = currentCampaign.main_category
        this.sub_category = currentCampaign.sub_category
      }

      if (currentCampaign.widgets !== undefined) {
        this.widgetsCampaign = currentCampaign.widgets.map(w => ({ value: w, text: w }))
      }
      this.countryCampaign = currentCampaign.country
      if (currentCampaign.country !== undefined) {
        const { countries } = currentCampaign.country.params
        if (countries.length !== 0) {
          this.countriesCampaign = countries.map(c => ({ value: c.toLowerCase(), text: c }))
        }
      }

      this.api_url = currentCampaign.api_url
      this.cpc_feed_api_processor = currentCampaign.cpc_feed_api_processor
    },
    async getWidgets() {
      const responseData = await useJwt.widgets()
      const widgets = responseData.data.widgets || []
      this.widgets = widgets.map(w => ({ value: w._id, text: w._id }))
    },
    changeCategory(value) {
      if (this.campaignSubCategory[value].length) {
        const [selectedSubcategory] = this.campaignSubCategory[value]
        this.sub_category = selectedSubcategory.value
      }
    },
    async getUserData() {
      const responseData = await useJwt.getUser(this.$route.params.userId)
      this.account = responseData.data.account || {}
      this.pageTitle = this.account.name
      this.pageSubtitle = this.account.email
      this.setBreabcrumbs()
    },
    async setBreabcrumbs() {
      this.breadcrumbItems = [
        {
          text: 'users',
          to: '/users',
        },
        {
          text: this.account.name,
          to: `/users/${this.$route.params.userId}`,
        },
        {
          text: 'campaigns',
          to: `/users/${this.$route.params.userId}/campaigns`,
        },
        {
          text: this.campaign.title,
          to: `/users/${this.$route.params.userId}/campaigns/${this.$route.params.id}`,
        },
        {
          text: 'settings',
          active: true,
        },
      ]
    },
    copyCode() {
      return `<script type="text/javascript" src="//phoenix-widget.com/static/js/advertorial.js"><${''}/script>`
    },
    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Code copied',
          icon: 'BellIcon',
        },
      })
      this.$refs.tarea.focus()
      this.$refs.tarea.select()
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Failed to copy code!',
          icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
<style>
.ck-editor__editable_inline {
  min-height: 200px
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
